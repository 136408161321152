import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "gatsby-theme-hypercore/src/templates/page";
import { createStyles, makeStyles } from "@material-ui/core";
import theme, { FONTS } from "../theme";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      ".HypMain-root": {
        background: theme.palette.background.paperSecondary,
        paddingBottom: theme.spacing(12),
      },
      ".HypHero-root": {
        minHeight: "480px",
      },
      ".HypHero-content": {
        margin: "auto auto 0 0",
        "& .MuiTypography-h1": {
          ...FONTS.KNOCKOUT.Lightweight,
          fontSize: theme.typography.pxToRem(54),
          [theme.breakpoints.up("sm")]: {
            fontSize: theme.typography.pxToRem(72),
          },
          letterSpacing: "1.5px",
        },
      },
      ".data-intro": {
        [theme.breakpoints.up("sm")]: {
          paddingTop: theme.spacing(1),
        },
      },
      ".data-citation": {
        paddingLeft: theme.spacing(3),
        borderLeft: "1px solid #CA432B",
      },
    },
  })
);

export default function GetTheDataTemplate(props) {
  useStyles();
  return <PageTemplate {...props} />;
}

export const pageQuery = graphql`
  query GetDataQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        meta {
          title
          description
          keywords
        }
      }
    }
  }
`;

